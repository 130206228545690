<template>
  <div>
    <el-container>
      <el-header height="50px">
        <MenuSecond :listName="'member'"></MenuSecond>
      </el-header>
      <!--  -->
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import MenuSecond from "../../components/menu/menu-second.vue";
export default {
  components: { MenuSecond },
  data() {
    return {};
  },

  mounted() {
    // console.log(this.$route);
  },

  methods: {},
};
</script>

<style lang="less" scoped>
.el-header {
  padding: 0;
  border-bottom: 1px solid #e5e9f2;
  background-color: #ffffff;
}
.el-main {
  height: calc(100vh - 60px);
  box-sizing: border-box;
  margin-top: 10px;
  background-color: #ffffff;
}
</style>