export default {
  /** 一级 */
  main: [
    {
      id: 1,
      name: "收银",
      path: "/cashier",
      icon: "el-icon-s-platform",
    },
    {
      id: 2,
      name: "会员",
      path: "/member",
      icon: "el-icon-user-solid",
    },
    {
      id: 3,
      name: "商城",
      path: "/shop",
      icon: "el-icon-s-shop",
    },
    {
      id: 4,
      name: "营销",
      path: "/marketing",
      icon: "el-icon-s-marketing",
    },
    {
      id: 5,
      name: "客情管理",
      path: "/customer",
      icon: "el-icon-user-solid",
    },
    {
      id: 6,
      name: "数据报表",
      path: "/report",
      icon: "el-icon-s-data",
    },
    {
      id: 7,
      name: "员工",
      path: "/employee",
      icon: "el-icon-s-custom",
    },
    {
      id: 8,
      name: "服务与商品",
      path: "/service",
      icon: "el-icon-s-goods",
    },
    {
      id: 9,
      name: "设置",
      path: "/setting",
      icon: "el-icon-s-tools",
    },
    {
      id: 10,
      name: "紧急报警",
      path: "/pressing",
      icon: "el-icon-message-solid",
    },
  ],

  /** 二级 */
  cashier: [
    //{ id: 1, name: '开单收银', path: '/cashier/order' },
    {
      id: 2,
      name: "水单审核",
      path: "/cashier/audit",
    },
    {
      id: 3,
      name: "预约管理",
      path: "/cashier/reservation",
    },
    {
      id: 4,
      name: "收支管理",
      path: "/cashier/balance",
    },
    //{ id: 5, name: '员工轮牌', path: '/cashier/round' }
  ],
  member: [
    // 会员管理
    {
      id: 1,
      name: "会员查询",
      path: "/member/manage",
    },
    {
      id: 2,
      name: "会员卡查询",
      path: "/member/select",
    },
    {
      id: 3,
      name: "分类设置",
      path: "/member/kind",
    },
    // {
    // 	id: 4,
    // 	name: '流失预警',
    // 	path: '/member/leave'
    // },
    {
      id: 5,
      name: "会员卡类型",
      path: "/member/card",
    },
    {
      id: 6,
      name: "会员卡业务处理",
      path: "/member/business",
    },
    // {
    // 	id: 7,
    // 	name: '跟踪记录',
    // 	path: '/member/'
    // },
    {
      id: 12,
      name: "会员概况分析",
      path: "/member/analysis",
    },
    {
      id: 13,
      name: "套卡项目",
      path: "/member/pack",
    },
    {
      id: 14,
      name: "赠送项目",
      path: "/member/dona",
    },
  ],
  maintain: [
    // 服务与商品管理
    {
      id: 1,
      name: "项目设置",
      path: "/service/item",
    },
    {
      id: 2,
      name: "提成设置",
      path: "/service/royalties",
    },
  ],
  setting: [
    // 设置
    {
      id: 1,
      name: "基础设置",
      path: "/setting/basic",
    },
    {
      id: 3,
      name: "营销设置",
      path: "/setting/notice",
    },
    {
      id: 4,
      name: "小程序设置",
      path: "/setting/applet",
    },
    {
      id: 5,
      name: "app设置",
      path: "/setting/app",
    },
    {
      id: 7,
      name: "管理员",
      path: "/setting/admin",
    },
    {
      id: 8,
      name: "日志",
      path: "/setting/log",
    },
  ],
  employees: [
    // 员工
    {
      id: 1,
      name: "员工",
      path: "/employee/staff",
    },
    {
      id: 2,
      name: "职位",
      path: "/employee/jobs",
    },
    {
      id: 3,
      name: "部门",
      path: "/employee/department",
    },
    {
      id: 4,
      name: "考勤",
      path: "/employee/attendance",
    },
  ],
  reservation: [],
  report: [
    //数据报表
    {
      id: 1,
      name: "门店数据",
      path: "/report/store",
    },
    {
      id: 2,
      name: "营业数据",
      path: "/report/reportBusiness",
    },
    {
      id: 3,
      name: "员工数据",
      path: "/report/employee",
    },
    {
      id: 4,
      name: "员工薪资",
      path: "/report/salary",
    },
    {
      id: 5,
      name: "财务数据",
      path: "/report/financial",
    },
    {
      id: 6,
      name: "会员数据",
      path: "/report/member",
    },
  ],
  marketing: [
    // {
    // 	id: 1,
    // 	name: '营销',
    // 	path: '/marketing/preview'
    // },
    // {
    // 	id: 1,
    // 	name: '售后追踪设置',
    // 	path: '/marketing/tracking'
    // },
    // {
    // 	id: 2,
    // 	name: '流失营销',
    // 	path: '/marketing/lost'
    // },
    // {
    // 	id: 3,
    // 	name: '消费周期管理',
    // 	path: '/marketing/consume-manage'
    // },
    // {
    // 	id: 4,
    // 	name: '生日营销',
    // 	path: '/marketing/birthday'
    // },
  ],
  inteExch: [
    // marketing下的特殊二级路由
    {
      id: 1,
      name: "积分兑换记录",
      path: "/marketing/inte-exch/inte-exch-record?list_name=inteExch",
    },
    {
      id: 2,
      name: "积分兑换物品",
      path: "/marketing/inte-exch/inte-exch-goods-list?list_name=inteExch",
    },
  ],
  distribution: [
    // marketing下的特殊二级路由
    {
      id: 1,
      name: "分销商",
      path: "/marketing/distribution/distributor?list_name=distribution",
    },
    {
      id: 2,
      name: "分销订单",
      path: "/marketing/distribution/orders?list_name=distribution",
    },
    {
      id: 3,
      name: "提现管理",
      path: "/marketing/distribution/withdraw?list_name=distribution",
    },
    {
      id: 4,
      name: "分销设置",
      path: "/marketing/distribution/setting?list_name=distribution",
    },
  ],
  pressing: [
    // {
    //   id: 1,
    //   name: "紧急主页",
    //   path: "/pressing/main",
    // },
    {
      id: 2,
      name: "报警管理",
      path: "/pressing/manage",
    },
  ],
  customer: [
    {
      id: 1,
      name: "客情管理",
      path: "/customer/manage",
    },
  ],
  shop: [
    {
      id: 1,
      name: "订单管理",
      path: "/shop/order",
    },
    {
      id: 2,
      name: "商品管理",
      path: "/shop/goods",
    },
    {
      id: 3,
      name: "拼团管理",
      path: "/shop/group",
    },
    {
      id: 4,
      name: "数据统计",
      path: "/shop/total",
    },
    {
      id: 5,
      name: "商城设置",
      path: "/shop/setting",
    },
  ],

  /** 三级 */
  ship: [
    // 会员资料
    {
      id: 1,
      name: "会员资料",
      path: "/member/manage/ship",
    },
    {
      id: 2,
      name: "已删会员",
      path: "/member/manage/delete",
    },
    {
      id: 3,
      name: "停用会员",
      path: "/member/manage/disable",
    },
  ],
  item: [
    // 服务与商品 ==> 项目设置
    {
      id: 1,
      name: "服务项目",
      path: "/service/item/maintain",
    },
    {
      id: 2,
      name: "项目分类",
      path: "/service/item/category",
    },
    {
      id: 3,
      name: "提成折扣分类",
      path: "/service/item/commission",
    },
    {
      id: 4,
      name: "预约分类",
      path: "/service/item/reservation",
    },
  ],
  royalties: [
    // 服务与商品 ==> 提成设置
    {
      id: 1,
      name: "项目提成",
      path: "/service/royalties/item",
    },
    // {
    // 	id: 1,
    // 	name: '项目提成',
    // 	path: '/service/royalties/commission'
    // },
    // {
    // 	id: 2,
    // 	name: '项目计时提成',
    // 	path: '/service/royalties/time'
    // },
    // {
    // 	id: 3,
    // 	name: '项目提成范围',
    // 	path: '/service/royalties/scope'
    // },
    // {
    // 	id: 4,
    // 	name: '分类批量设置',
    // 	path: '/service/royalties/category'
    // }
  ],
  store: [
    //数据报表 ==> 门店数据
    {
      id: 1,
      name: "门店业绩总览",
      path: "/report/store/PerformanceSummary",
    },
    {
      id: 2,
      name: "营收报表",
      path: "/report/store/revenue",
    },
    {
      id: 3,
      name: "店内业绩统计表",
      path: "/report/store/performance",
    },
    {
      id: 4,
      name: "各门店业绩汇总",
      path: "/report/store/each",
      head: 1,
    },
    {
      id: 5,
      name: "跨店消费统计表",
      path: "/report/store/finance",
    },
    {
      id: 6,
      name: "项目分类统计明细表",
      path: "/report/store/class-detail-statis",
    },
    {
      id: 7,
      name: "项目分类付款统计表",
      path: "/report/store/class-payment-statis",
    },
    // { id: 4, name: '业绩类别统计表', path: '/report/store' }
    //{ id: 1, name: '门店营业数据', path: '/report/store/business' },
    //{ id: 2, name: '门店营业明细', path: '/report/store/detail' }
  ],
  salary: [
    {
      id: 1,
      name: "达标提成",
      path: "/report/salary/quailfy",
    },
  ],
  employee: [
    //数据报表 ==> 员工数据
    {
      id: 1,
      name: "员工业绩汇总表",
      path: "/report/employee/total",
    },
    {
      id: 2,
      name: "各门店员工业绩汇总排行",
      path: "/report/employee/ranking",
      head: 1,
    },
    {
      id: 3,
      name: "员工提成汇总表",
      path: "/report/employee/roya",
    },
    // {
    // 	id: 4,
    // 	name: '测试',
    // 	path: '/report/employee/text',
    // },
    {
      id: 5,
      name: "员工项目分类汇总表",
      path: "/report/employee/class-statis",
    },
    {
      id: 6,
      name: "员工项目分类趋势分析",
      path: "/report/employee/class-trend",
    },
    {
      id: 7,
      name: "员工业绩分析表",
      path: "/report/employee/perfor-analyse",
    },
    // {
    //   id: 8,
    //   name: "员工分类业绩月度统计",
    //   path: "/report/employee/monthly",
    // },
  ],
  financial: [
    // 财务数据 ==> 收支统计
    // { id: 1, name: '工资发放', path: '/report/financial' },
    {
      id: 2,
      name: "损益表按现金业绩",
      path: "/report/financial/profit-by-cash",
    },
    // { id: 3, name: '跨店对账', path: '/report/financial' },
    // { id: 4, name: '收支统计', path: '/report/financial/income' },
    // { id: 4, name: '营收报表', path: '/report/financial/revenue' }
  ],
  notice: [
    // 设置 ==> 短息通知设置
    {
      id: 1,
      name: "短信设置",
      path: "/setting/notice/send",
    },
    {
      id: 2,
      name: "评价设置",
      path: "/setting/notice/evaluate",
    },
  ],
  applet: [
    // 设置 ==> 小程序设置
    {
      id: 1,
      name: "基础设置",
      path: "/setting/applet/basis",
    },
    {
      id: 2,
      name: "WIFI设置",
      path: "/setting/applet/wifi",
    },
    {
      id: 3,
      name: "微助理二维码设置",
      path: "/setting/applet/storeQrcode",
    },
    {
      id: 4,
      name: "页面管理",
      path: "/setting/applet/page",
    },
    {
      id: 5,
      name: "功能设置",
      path: "/setting/applet/feature",
    },
    {
      id: 6,
      name: "全局设置",
      path: "/setting/applet/global",
    },
  ],
  app: [
    // 设置 ==> app设置
    {
      id: 1,
      name: "二维码展示",
      path: "/setting/app/attendance",
    },
    {
      id: 2,
      name: "基础设置",
      path: "/setting/app/basis",
    },
  ],
  card: [
    // 会员 ==> 会员卡设置
    {
      id: 1,
      name: "会员卡",
      path: "/member/card/list",
    },
    {
      id: 2,
      name: "已删会员卡",
      path: "/member/card/list?cardState=3",
    },
    {
      id: 3,
      name: "已停用会员卡",
      path: "/member/card/list?cardState=2",
    },
    {
      id: 4,
      name: "会员卡办卡协议",
      path: "/member/card/agreement",
    },
  ],
  // inteExch: [	// 营销 ==> 积分商城
  // 	{
  // 		id: 1,
  // 		name: '积分兑换记录',
  // 		path: '/marketing/inte-exch/inte-exch-record'
  // 	},
  // 	{
  // 		id: 2,
  // 		name: '积分兑换物品',
  // 		path: '/marketing/inte-exch/inte-exch-goods-list',
  // 	}
  // ],
  attendance: [
    // 员工 ==> 考勤
    {
      id: 1,
      name: "员工考勤明细表",
      path: "/employee/attendance/work-detail",
    },
    {
      id: 2,
      name: "员工考勤统计表",
      path: "/employee/attendance/work-statistics",
    },
  ],
  basic: [
    // 设置 ==> 基础设置
    {
      id: 1,
      name: "折扣比例",
      path: "/setting/basic/discount",
    },
    {
      id: 2,
      name: "单位设置",
      path: "/setting/basic/monad",
    },
    {
      id: 3,
      name: "水单号设置",
      path: "/setting/basic/BillNo",
    },
    {
      id: 4,
      name: "消息模板",
      path: "/setting/basic/template",
    },
    {
      id: 5,
      name: "房间管理",
      path: "/setting/basic/room",
    },
    {
      id: 6,
      name: "基础参数",
      path: "/setting/basic/parameter",
    },
  ],
  kind: [
    {
      id: 1,
      name: "会员分类",
      path: "/member/kind/category",
    },
    {
      id: 2,
      name: "维度分类",
      path: "/member/kind/dimension",
    },
    {
      id: 3,
      name: "会员卡分类设置",
      path: "/member/kind/card-classify",
    },
    {
      id: 4,
      name: "标签管理",
      path: "/member/kind/label",
    },
    {
      id: 5,
      name: "顾客来源",
      path: "/member/kind/source",
    },
  ],
  business: [
    {
      id: 1,
      name: "会员欠款",
      path: "/member/business/debt",
    },
    {
      id: 2,
      name: "会员退款",
      path: "/member/business/refund",
    },
    {
      id: 3,
      name: "套卡次数转金额",
      path: "/member/business/trunMoney",
    },
    {
      id: 4,
      name: "套卡次数删除",
      path: "/member/business/reduce",
    },
  ],
  reportMember: [
    {
      id: 1,
      name: "会员消费分析",
      path: "/report/member/expend-analysis",
    },
    {
      id: 2,
      name: "会员消费分类分析",
      path: "/report/member/expense-level-analysis",
    },
    {
      id: 3,
      name: "自定义消费级别分析",
      path: "/report/member/expense-level-custom",
    },
  ],
  reportBusiness: [
    // 报表 ==> 营业数据
    {
      id: 1,
      name: "项目消费",
      path: "/report/reportBusiness/itemsConsumption",
    },
    {
      id: 2,
      name: "开充卡",
      path: "/report/reportBusiness/openCharge",
    },
  ],
  goods: [
    {
      id: 1,
      name: "商品列表",
      path: "/shop/goods/list",
    },
    {
      id: 2,
      name: "商品分类",
      path: "/shop/goods/category",
    },
  ],
  group: [
    {
      id: 1,
      name: "拼团商品",
      path: "/shop/group/wares",
    },
    {
      id: 2,
      name: "拼团记录",
      path: "/shop/group/record",
    },
  ],
  shopSetting: [
    {
      id: 1,
      name: "基础设置",
      path: "/shop/setting/basic",
    },
    {
      id: 2,
      name: "主页设置",
      path: "/shop/setting/home",
    },
    {
      id: 3,
      name: "销售关系",
      path: "/shop/setting/sale",
    },
  ],
  shopTotal: [
    {
      id: 1,
      name: "统计总览",
      path: "/shop/total/overview",
    },
    {
      id: 2,
      name: "员工销售统计",
      path: "/shop/total/emplGroupTotal",
    },
  ],

  /** 四级 */
  detail: [
    //数据报表 ==> 门店数据 ==> 门店营业明细
    {
      id: 1,
      name: "劳动",
      path: "/report/store/detail/labor",
    },
    {
      id: 2,
      name: "商品销售",
      path: "/report/store/detail/sales",
    },
    {
      id: 3,
      name: "开冲卡",
      path: "/report/store/detail/openCard",
    },
    {
      id: 4,
      name: "疗程卡(赠送项目)",
      path: "/report/store/detail/treatmentCard",
    },
  ],
  employeeDetail: [
    //数据报表 ==> 员工数据 ==> 员工销售明细
    {
      id: 1,
      name: "劳动",
      path: "/report/employee/detail/labor",
    },
    {
      id: 2,
      name: "商品",
      path: "/report/employee/detail/sales",
    },
    {
      id: 3,
      name: "开充卡",
      path: "/report/employee/detail/openCard",
    },
    {
      id: 4,
      name: "疗程卡",
      path: "/report/employee/detail/treatmentCard",
    },
  ],
  page: [
    {
      id: 1,
      name: "页面列表",
      path: "/setting/applet/page/management",
    },
    {
      id: 2,
      name: "页面设计",
      path: "/setting/applet/page/design",
    },
  ],
};
